<template>
  <div class="holy-places">
    <h1>Главное в городе</h1>
    <swiper class="swiper" :options="{slidesPerView: 'auto', touchRatio: 2}">
      <swiper-slide v-for="place in currentDataSet" :key="place.id">
        <holy-place
          :name="place.title"
          :img="place.images[Object.keys(place.images)[0]].replace('extra', 'small')"
          @click.native="goToObject(place)"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import HolyPlace from "@/components/Parts/SquareCard";
import { mapGetters } from 'vuex';

export default {
  name: "HolyPlaces",

  components: {
    HolyPlace
  },

  computed: {
    ...mapGetters(["GET_PLACES"]),

    currentDataSet() {
      const dataSet = this.GET_PLACES.filter(item => item.category?.id == 1);
      dataSet.splice(8);
      return dataSet;
    }
  },

  methods: {
    goToObject(object) {
      this.$store.dispatch("setSelectedObject", object);
      this.$router.push({ path: `/object/${object.id}` });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.holy-places {
  .swiper-slide {
    width: 224px;
    margin-right: 24px;

    &:first-child {
      margin-left: 32px;
    }

    &:last-child {
      margin-right: 32px;
    }
  }
}
</style>
