<template>
  <content-wrapper class="home-screen">
    <div class="main-tiles">
      <swiper
        class="swiper"
        :options="{
          slidesPerView: 'auto',
          touchRatio: 2
        }"
      >
        <swiper-slide>
          <router-link to="/transport">
            <tile-big img="tile-1.png" title="Транспорт" />
          </router-link>
        </swiper-slide>
        <swiper-slide>
          <router-link to="/travelGuide">
            <tile-big img="zhv-cover.jpg" title="Путеводитель по городу" />
          </router-link>
        </swiper-slide>
        <swiper-slide>
          <router-link to="/events">
            <tile-big img="tile-8.jpg" title="Афиша мероприятий" />
          </router-link>
        </swiper-slide>
        <swiper-slide>
          <router-link to="/excursions">
            <tile-big img="tile-6.jpg" title="Маршруты и экскурсии" />
          </router-link>
        </swiper-slide>
        <swiper-slide>
          <router-link to="/guide">
            <tile-big img="tile-7.jpg" title="Контакты экстренных служб" />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>

    <stories />
<!--    <div class="banner">-->
<!--      <img src="@/assets/img/banners/banner-1.jpg">-->
<!--    </div>-->
    <holy-places />

    <!-- <events /> -->
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import TileBig from "@/components/Parts/BigCard";
import HolyPlaces from "@/components/HolyPlaces";
import Stories from "@/components/Stories";
import Events from "@/components/Events";
//import PlaceCard from "@/components/Parts/PlaceCard";

export default {
  name: "HomeScreen",
  components: {
    Stories,
    ContentWrapper,
    TileBig,
    HolyPlaces,
    Events,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.home-screen {
  /deep/ h1 {
    font-size: 50px;
    font-weight: bold;
    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(#fff, 0.97);
    margin-top: 64px;
    margin-left: 32px;
    margin-bottom: 28px;
  }

  .main-tiles {
    height: 638px;
    overflow: hidden;

    .swiper {
      padding-top: 56px;
      padding-bottom: 56px;

      .swiper-slide {
        width: 356px;
        margin-left: 24px;
        margin-right: 8px;

        &:last-child {
          margin-right: 32px;
        }
      }
    }
  }

  .saints {
    padding-bottom: 32px;
  }

  .holy-places {
    margin-bottom: 48px;
  }

  .events {
    /deep/ .tags {
      margin-top: -32px;
    }
  }

  .banner {
    padding: 0 35px;

    img {
      width: 100%;
      border-radius: 12px;
    }
  }
}
</style>
